<!-- 구매실적 -->
<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast md-container">
        <!-- 전사 통합 -->
        <div class="inner membership-detail">
          <div class="title-wrap left">
            <p v-if="'A' != chanlGradeInfo.chanlGbnCd">
              <img :src="chanlGradeInfo.chanlLogo" :alt="chanlGradeInfo.chanlNm" class="logo" />
            </p>
            <p v-else>{{ chanlGradeInfo.chanlNm }}</p>
          </div>
          <div class="cont-wrap">
            <div class="contents">
              <!-- 구매 정보 -->
              <div class="detail-infobox">
                <div class="text">
                  <span class="desc">
                    <b>{{ chanlGradeInfo.month }}</b
                    >개월({{ chanlGradeInfo.period }}) 동안<br /><b class="point"
                      >{{ chanlGradeInfo.ordCnt }}{{ chanlGradeInfo.dayOrNum }} / {{ chanlGradeInfo.ordAmt }}원</b
                    >
                    구매하셨습니다.
                  </span>
                </div>
              </div>
              <!-- //구매 정보 -->

              <!-- progress -->
              <!-- 작은 사이즈 .sm 추가 -->
              <div class="member-progress sm">
                <div class="progress-bar" ref="barWidth">
                  <p class="bar" :style="{width: chanlGradeInfo.percent + '%'}"></p>
                </div>
                <ul class="datalist extension">
                  <li
                    v-for="(items, index) in chanlGradeInfo.gradeStandard.slice().reverse()"
                    :key="index"
                    :class="{on: items.gradeIdx <= chanlGradeInfo.gradeIndex}"
                  >
                    <span class="grade">{{ items.grade }}</span>
                  </li>
                </ul>
              </div>
              <!-- //progress -->

              <!-- 브랜드 등급 -->
              <div class="grade-detail">
                <!-- 브랜드 등급 기준 -->
                <div class="detail-infobox border">
                  <header class="sect-head divide">
                    <div class="head">
                      <h3 class="ttl md">브랜드 등급 기준(최근 {{ chanlGradeInfo.month }}개월)</h3>
                    </div>
                  </header>
                  <!-- 채널 별 등급기준 안내 -->
                  <div class="grade">
                    <p
                      v-for="(item, index1) in chanlGradeInfo.gradeStandard.slice().reverse()"
                      :key="index1"
                      v-show="item.isExpos"
                    >
                      <dfn :class="item.grade.toLowerCase()">{{ item.grade }}</dfn
                      >{{ item.exposStandard }}
                    </p>
                  </div>
                </div>
                <!-- //브랜드 등급 기준 -->

                <!-- 등급산정기준 -->
                <div class="notice">
                  <header class="sect-head divide">
                    <div class="head">
                      <h3 class="ttl md">등급 산정 기준</h3>
                    </div>
                  </header>
                  <ul class="dot-list">
                    <li v-for="(itm, index2) in notices" :key="index2">{{ itm }}</li>
                  </ul>
                </div>
                <!-- //등급산정기준 -->
              </div>
              <!-- //브랜드 등급 -->
            </div>
          </div>
        </div>
        <div class="close">
          <!-- <button aria-label="닫기" @click="onChanlDetail"></button> -->
          <button aria-label="닫기" @click="onLayer('detail')"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalChanlDetail',
  props: {
    chanlGradeInfo: Object
  },
  data() {
    return {
      // <!-- 기본 상수 부분 -->
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      // chanlGradeArry: this.$store.state.integrate.chanlGradeArry, // 등급별 혜택(채널) 정보
      chanlGradeArry: this.$store.state.integrate.newChanlGradeArry, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      // <!-- //기본 상수 부분 -->}
      // 데이터 부분
      notices: []
    }
  },
  created() {
    console.log('')
    console.log('######## 공통 채널 등급/실적 레이어 UI ########')
    console.log('')
  },
  mounted() {
    console.log('## ModalChanlDetail Layer layerType ')
    // console.log(this.chanlGradeInfo)
    this.notices = this.getChanlArr(this.chanlGradeInfo.chanlGbnCd).notice
  },
  methods: {
    // 상수 값 추출 함수
    getChanlArr(chanlGbnCd) {
      return this.chanlGradeArry.find((el) => el.chanlGbnCd == chanlGbnCd)
    },
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      this.$emit('onLayer', type, false)
      return
    }
  }
}
</script>
