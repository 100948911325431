// 신규 통합멤버십/패밀리 개발
// 폴더 정리 본.
import IntgMemshpGrade from '@/views/integrate/IntgMemshpGrade.vue'
import IntgFamilyInfo from '@/views/integrate/IntgFamilyInfo.vue'
import IntgFamilyName from '@/views/integrate/IntgFamilyName.vue'
import IntgFamilyJoin from '@/views/integrate/IntgFamilyJoin.vue'
import IntgFamilyInvite from '@/views/integrate/IntgFamilyInvite.vue'
import IntgFamilyAlready from '@/views/integrate/IntgFamilyAlready.vue'
import IntgFamilyExit from '@/views/integrate/IntgFamilyExit.vue'
import IntgGradeStandard from '@/views/integrate/IntgGradeStandard.vue'
import TempIntgMemshpGrade from '@/views/integrate/TempIntgMemshpGrade.vue'

const memberRouter = [
  {
    path: '/montblanc/mem/monthGrade',
    redirect: '/montblanc/intg/memshpGrade'
  },

  //통합멤버십 UI 개선 부분
  // 신규(이전) 멤버십/패밀리 등급/실적 
  {
    path: '/montblanc/intg/memshpGrade',
    name: 'IntgMemshpGrade',
    component: IntgMemshpGrade
  },
  // 통합멤버십 패밀리 소계(생성 시작)
  {
    path: '/montblanc/intg/familyInfo',
    name: 'IntgFamilyInfo',
    component: IntgFamilyInfo
  },
  // 통합멤버십 패밀리 만들기(생성)
  {
    path: '/montblanc/intg/familyName',
    name: 'IntgFamilyName',
    component: IntgFamilyName
  },
  // 톰합멤버십 패밀리 초대/수락
  {
    path: '/montblanc/intg/familyJoin',
    name: 'IntgFamilyJoin',
    component: IntgFamilyJoin
  },
  // 톰합멤버십 패밀리 초대/수락 Params (카카오 진입)
  {
    path: '/montblanc/intg/familyInvite/:famyGrpId',
    name: 'IntgFamilyInvite',
    component: IntgFamilyInvite
  },
  // 패밀리그룹 초대/수락 이슈 : 24.06.11
  // 톰합멤버십 패밀리 초대/수락 Query (카카오 진입)
  {
    path: '/montblanc/intg/familyInvite',
    name: 'IntgFamilyInviteQuery',
    component: IntgFamilyInvite
  },
  // 톰합멤버십 패밀리 (초대/수락) 기 가입 확인
  {
    path: '/montblanc/intg/familyAlready',
    name: 'IntgFamilyAlready',
    component: IntgFamilyAlready
  },
  // 통합멤버십 패밀리 탈퇴(처리)
  {
    path: '/montblanc/intg/familyExit',
    name: 'IntgFamilyExit',
    component: IntgFamilyExit,
    props: true
  },
  // 톰합멤버십 패밀리 등급 기준 안내
  {
    path: '/montblanc/intg/gradeStandard',
    name: 'IntgGradeStandard',
    component: IntgGradeStandard,
    props: true
  },
  // 통합멤버십 패밀리 소계(생성 시작)
  {
    path: '/montblanc/intg/whatMemshp',
    name: 'WhatIntgMemshp',
    component: IntgFamilyInfo
  },
  // 2차 개발 : 멤버십 2차 개발건.
  {
    path: '/montblanc/intg/tempmemshpGrade',
    name: 'TempIntgMemshpGrade',
    component: TempIntgMemshpGrade
  }
  // 통합멤버십 UI 개선 부분
]

export default memberRouter
