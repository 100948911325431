<template>
  <div class="membership-box integrate">
    <!-- 패밀리 탈퇴 -->
    <div class="family-member-wrap">
      <!-- 탈퇴 -->
      <section class="family-quit">
        <header class="sect-head">
          <div class="head-colum">
            <h2 class="ttl">패밀리 그룹 나가기</h2>
            <sub v-if="isBnftChange">
              지금 패밀리 그룹을 나가시면 <b>혜택이 줄어 들어요!</b><br />다음달 등급이 <b>{{ nxtGrdNm }}</b
              >에서 <b>{{ chgGrdNm }}</b
              >로 변경됩니다.
            </sub>
            <sub v-else>
              지금 패밀리를 나가셔도 <br />다음달 등급은 <b>{{ nxtGrdNm }}</b> 으로 유지됩니다.
            </sub>
          </div>
        </header>

        <!-- tab -->
        <div v-if="isBnftChange" class="tabs">
          <button
            class="tab"
            v-for="(tab, index) in tabList"
            :key="index"
            @click.prevent="currentTab = index"
            :class="{active: currentTab === index}"
          >
            <span>{{ tab.name }}</span>
          </button>
        </div>
        <!-- //tab -->

        <!-- 패밀리 유지 시시 -->
        <div class="tab-content" v-if="currentTab === 0 && null != nxtBenefit && nxtBenefit.gradeIndex > 0">
          <!-- 혜택박스 -->
          <div class="benefit-card">
            <div
              v-for="(items, index) in nxtBenefit.benefit"
              :key="index"
              v-show="items.chanlGbnCd != 'E'"
              class="card-item"
            >
              <div class="item-head">
                <div class="tit">
                  <img :src="items.img2" :alt="items.chanlNm" />
                  <h2 v-if="'H' === items.chanlGbnCd">
                    <div v-for="(exposCpn, idx6) in items.exposCouponArr" :key="idx6" class="blst">
                      <strong>{{ exposCpn.cpn }}</strong> <span>{{ exposCpn.sub }}</span>
                    </div>
                  </h2>
                  <h2 v-else>
                    <span v-if="null != items.condition && '' != items.condition">{{ items.condition }}</span>
                    {{ items.exposCoupon }}<br />{{ items.exposCoupon1 }}
                  </h2>
                </div>
              </div>
              <div v-if="'H' == items.chanlGbnCd && nxtBenefit.gradeIndex >= vipIndex" class="vip-benefit-zone">
                <dl>
                  <dt>VIP 전용 혜택</dt>
                  <dd>
                    <ul class="dot-list">
                      <li v-for="(itm, idx) in items.addBnft" :key="idx">{{ itm }}</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <p class="desc">* 자세한 등급별 혜택 내용은 각 사이트에서 확인 할 수 있습니다.</p>
          </div>
          <!-- //혜택박스 -->
        </div>

        <!-- 패밀리 탈퇴 시 -->
        <div class="tab-content" v-if="currentTab === 1 && null != chgBenefit && chgBenefit.gradeIndex > 0">
          <!-- 혜택박스 -->
          <div class="benefit-card">
            <div
              v-for="(items, index) in chgBenefit.benefit"
              :key="index"
              v-show="items.chanlGbnCd != 'E'"
              class="card-item"
            >
              <div class="item-head">
                <div class="tit">
                  <img :src="items.img2" :alt="items.chanlNm" />
                  <h2 v-if="'H' === items.chanlGbnCd">
                    <div v-for="(exposCpn, idx6) in items.exposCouponArr" :key="idx6" class="blst">
                      <strong>{{ exposCpn.cpn }}</strong> <span>{{ exposCpn.sub }}</span>
                    </div>
                  </h2>
                  <h2 v-else>
                    <span v-if="null != items.condition && '' != items.condition">{{ items.condition }}</span
                    >{{ items.exposCoupon }}<br />{{ items.exposCoupon1 }}
                  </h2>
                </div>
              </div>
              <div v-if="'H' == items.chanlGbnCd && chgBenefit.gradeIndex >= vipIndex" class="vip-benefit-zone">
                <dl>
                  <dt>VIP 전용 혜택</dt>
                  <dd>
                    <ul class="dot-list">
                      <li v-for="(itm, idx) in items.addBnft" :key="idx">{{ itm }}</li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <p class="desc">* 자세한 등급별 혜택 내용은 각 사이트에서 확인 할 수 있습니다.</p>
          </div>
          <!-- //혜택박스 -->
        </div>
      </section>
      <!-- //탈퇴 -->
    </div>
    <!-- //패밀리 탈퇴 -->

    <!-- 서비스 유의사항 -->
    <div class="family-notice drop-box" :class="isFmlyNotice == true ? 'drop-open' : ''">
      <div class="drop-tit">
        <button type="button" class="noti-tit" @click="onLayer('notice')">서비스 유의사항</button>
      </div>
      <div class="drop-cont">
        <ul class="dot-list">
          <li>패밀리를 나가는 경우, 해당 패밀리 실적을 반영한 등급혜택은 당월 또는 익월까지만 유지됩니다.</li>
          <li>매월 1일 ~ 25일 내 패밀리를 나간 경우 : 당월 말일까지 해당 패밀리 등급 적용</li>
          <li>매월 26일 ~ 말일 내 패밀리를 나간 경우 : 익월 말일까지 해당 패밀리 등급 적용</li>
          <li>
            패밀리에서 나가시더라도 언제든지 새로운 패밀리 생성/초대 수락을 통한 가입이 가능합니다.(단, 최초 가입 혜택인
            포인트 1,000점은 지급되지 않습니다.)
          </li>
        </ul>
      </div>
    </div>
    <!-- //서비스 유의사항 -->

    <!-- 버튼 -->
    <div class="b-btn-wrap type2">
      <div>
        <button type="button" class="white-btn lg" @click="goPage('exit')"><span>나갈게요</span></button>
      </div>
      <div>
        <button type="button" class="check lg" @click="goPage('cancel')"><span>계속 이용할게요</span></button>
      </div>
    </div>
    <!-- //버튼 -->

    <!-- 패밀리 서비스 탈퇴 완료 레이어 -->
    <ModalFamilyComplete v-if="isFmlyExit" @onLayer="onLayer" :layerType="'exit'" />
    <!-- 공통 Alert 레이어 -->
    <ModalAlert v-if="isAlert" @onLayer="onLayer" :alert="alert" :type="alert.type" />
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
  </div>
</template>

<script>
import ModalFamilyComplete from '@/views/comm/integrate/ModalFamilyComplete.vue'
import ModalAlert from '@/views/comm/integrate/ModalAlert.vue'
import TagManager from '@/views/comm/TagManager.vue'

export default {
  name: 'IntgFamilyExit',
  inject: ['sendTitle'],
  data() {
    return {
      title: 'GS리테일 통합멤버십',
      // 기본 정보
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      chanlDomain: this.$store.state.apiStore.bodyParam.chanlDomain,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      // gradeArray: this.$store.state.integrate.gradeArray, // 채널별 등급 정보
      gradeArray: this.$store.state.integrate.newGradeArray, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      // gradeBnftArray: this.$store.state.integrate.gradeBnftArray, // 등급별 혜택(채널) 정보
      gradeBnftArray: this.$store.state.integrate.newGradeBnftArray, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      // 기본 정보
      // 레이어
      isFmlyExit: false, //서비스 유의사항 보기,
      isFmlyNotice: true, //패밀리서비스 유의사항 보기
      // layerType: 'exit', //레이어 타입 설정
      // 데이터
      famyGrpId: '', //패밀리그룹ID
      isGrdDiff: false, //멤버십/패밀리 다음달 등급 비교 여부
      isBnftChange: false, //탈퇴시 등급 혜택 변경 여부
      memNextGradeData: {}, //멤버십 다음달 등급 데이터
      fmlyNextGradeData: {}, //패밀리 다음달 등급 데이터
      nxtGrdIdx: '', //다음달 등급 Index
      nxtGrdCd: '', //다음달 등급 Index
      nxtGrdNm: '', //다음달 등급명
      chgGrdIdx: '', //변경 다음달 등급 Index
      chgGrdCd: '', //변경 다음달 등급 Index
      chgGrdNm: '', //변경 다음달 등급명
      currentTab: 0,
      tabList: [{name: '패밀리 유지 시'}, {name: '패밀리 탈퇴 시'}],
      nxtBenefit: {}, //패밀리유지시 혜택
      chgBenefit: {}, //패밀리탈퇴시 혜택

      // <!-- 알럿(예외처리) 함수 -->
      isAlert: false, //알럿(예외처리) 노출 여부
      // 알럿(예외처리) 정보
      alert: {
        type: 'error', // 유형
        code: '', // (노출)코드
        message: '' // (노출)메시지
      }, //알럿(예외처리) 코드/메세지 정보
      // <!-- //알럿(예외처리) 함수 -->
      // vvipIndex: 3,
      // vipIndex: 2,
      vipIndex: 3, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]

      isTest: false
    }
  },
  components: {
    ModalFamilyComplete,
    ModalAlert,
    // 앰플리튜드 기능 추가
    TagManager
  },
  created() {
    // console.log(this.$route)
    console.log('')
    console.log('######## 패밀리 그룹 탈퇴 UI ########')
    console.log('')

    this.famyGrpId = this.$route.params.famyGrpId
    console.log('### 패밀리 그룹ID 파람 : ' + this.famyGrpId)
  },
  mounted() {
    document.title = this.title
    this.sendTitle(this.title)
    window.scrollTo(0, 0)

    // 패밀리 다음달 등급 조회 단계
    // CASE 1 : 조회 일 26일 이후인 경우
    //     1단계 : 패밀리 다음달 등급(패밀리 다음달 등급 산정) 조회
    // CASE 2 : 조회 일 26일 이전인 경우
    //     1단계 : 멤버십 다음달 등급(패밀리 다음달 등급 미산정) 조회
    //     2단계 : 패밀리 유지 시 다음달 등급(다음달 예상등급) 조회 결과 비교
    // console.log(this.getGradeBnftArray(2))
    this.isTest = false
    if (this.isTest) {
      console.log('## 1단계 : 테스트 데이터 설정')
      this.isFmlyExit = true
      var stdDayAfter = false
      if (stdDayAfter) {
        this.retrieveChNxtmmGrdRlrslt(true)
      } else {
        this.retrieveChNxtmmGrdRlrslt(false)
      }
    } else {
      this.$dayjs().format('YYYYMMDD')
      this.$dayjs().format('YYYYMM26')
      if (this.$dayjs().format('YYYYMMDD') > this.$dayjs().format('YYYYMM26')) {
        console.log('## 1단계 : 조회 26일 후 패밀리 다음달 등급(패밀리 다음달 등급 산정) 조회')
        this.retrieveChNxtmmGrdRlrslt(true)
      } else {
        console.log('## 2단계 : 조회 26일 전 멤버십 다음달 등급(패밀리 다음달 등급 미산정) 조회')
        this.retrieveChNxtmmGrdRlrslt(false)
      }

      // 앰플리튜드 추가
      this.tagging('VIEW-INTG-FMLY-EXIT')
    }
  },
  methods: {
    // <!-- 상수 값 추출 함수 -->
    getGradeArr(grdCd) {
      return this.gradeArray.find((el) => el.grdCd == grdCd)
    },
    getGradeBnftArray(grdIdx) {
      return this.gradeBnftArray.find((el) => el.gradeIndex == grdIdx)
    },
    // <!-- //상수 값 추출 함수 -->

    // URL이동 통합 관리
    goPage(type) {
      console.log('>>> 링크 화면이동 처리 type : ' + type)
      switch (type) {
        case 'cancel': //패밀리 탈퇴 취소 (계속 이용할께요)
          console.log('>>> 패밀리 탈퇴 취소 (계속 이용할께요) 뒤로가기 ....')
          // 앰플리튜드 추가
          this.tagging('CLICK-INTG-FMLY-EXIT-CNL')
          this.$router.go(-1)
          break
        case 'exit': //패밀리 그룹 탈퇴 요청
          console.log('>>> 패밀리 그룹 탈퇴 요청..')
          this.saveChFamyGrpWthdrw()
          // 앰플리튜드 추가
          this.tagging('CLICK-INTG-FMLY-EXIT')
          break
        default:
          break
      }
      return
    },
    // 레이어 통합 관리
    onLayer(type, boolean) {
      console.log('>>> type : ' + type + ', boolean : ' + boolean)
      switch (type) {
        case 'complete': //패밀리그룹 서비스 가입 완료 레이어 (비)노출 처리
          this.isFmlyExit = boolean
          if (boolean) {
            this.bodyFixed()
            // 앰플리튜드 추가
            this.tagging('VIEW-INTG-FMLY-EXIT-COMP')
          } else {
            this.bodyFixedNone()
          }
          break
        case 'notice': // 서비스 유의사항 레이어 (비)노출 처리
          if (this.isFmlyNotice) {
            this.isFmlyNotice = false
          } else {
            this.isFmlyNotice = true
          }
          break
        case 'alert': // 알럿(예외처리) 레이어 (비)노출 처리
          this.isAlert = boolean

          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
            console.log('알럿(예외처리) 닫은 후 뒤로가기 처리')
            this.windowMove()
          }
          break
        default:
          break
      }
      return
    },
    // <!-- 알럿(예외처리) 함수 -->
    onAlert(code, message) {
      console.log('>>> onAlert(알럿/예외처리) 기능 code : ' + code + ', message : ' + message)
      this.alert.code = code
      this.alert.message = message
      this.onLayer('alert', true)
    },
    //통합 화면 채널이동(닫기) 처리
    windowClose() {
      return
    },
    //통합 화면 이동처리
    windowMove() {
      this.$router.go(-1)
      return
    },
    // 모달
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
    },
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
    },
    //정리 부분

    // API 호출
    // G002		retrieveChNxtmmGrdRlrslt	다음달 고객 멤버십/패밀리 등급 실적 조회 API
    async retrieveChNxtmmGrdRlrslt(isFamily) {
      console.log(
        '#### methods ' + (isFamily ? '패밀리' : '멤버십') + ' 서비스[G001 : retrieveChNxtmmGrdRlrslt] str ####'
      )
      let callApiUrl = '/retrieveChNxtmmGrdRlrslt' //다음달 멤버십 등급
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChNxtmmGrdRlrslt',
        stdYm: this.$dayjs().format('YYYYMM'), //기준 년월
        memGbnCd: isFamily ? 'F' : 'I' //통합(개인)/패밀리
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post(callApiUrl, bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (null != res && null != resData && '0000' == resData.resCd) {
            console.log(resData)
            if (isFamily) {
              console.log('### 탈퇴 전 패밀리 다음달 등급 조회 ')
              this.fmlyNextGradeData = resData
              console.log(this.fmlyNextGradeData)

              if (this.isGrdDiff) {
                console.log('>> 멤버십/패밀리 다음달 등급 비교 시작')
                var fmlyGradeIdx = this.getGradeArr(resData.expctLastCustGrdCd).grdIdx
                var fmlyGradeCd = resData.expctLastCustGrdCd
                var fmlyGradeNm = this.getGradeArr(resData.expctLastCustGrdCd).grdNm
                var memGradeIdx = this.getGradeArr(this.memNextGradeData.expctLastCustGrdCd).grdIdx
                var memGradeCd = this.memNextGradeData.expctLastCustGrdCd
                var memGradeNm = this.getGradeArr(this.memNextGradeData.expctLastCustGrdCd).grdNm
                console.log(
                  '>> fmlyGrdIdx : ' + fmlyGradeIdx + ', fmlyGrdCd : ' + fmlyGradeCd + ', fmlyGrdNm : ' + fmlyGradeNm
                )
                console.log(
                  '>> memGrdIdx : ' + memGradeIdx + ', memGrdCd : ' + memGradeCd + ', memGrdNm : ' + memGradeNm
                )
                if (fmlyGradeIdx > memGradeIdx) {
                  console.log('>> 현 패밀리 그룹 등급이 멤버십 등급보다 높은경우')
                  this.isBnftChange = true
                  this.nxtGrdIdx = fmlyGradeIdx
                  this.nxtGrdCd = fmlyGradeCd
                  this.nxtGrdNm = fmlyGradeNm
                  this.nxtBenefit = this.getGradeBnftArray(fmlyGradeIdx)

                  this.chgGrdIdx = memGradeIdx
                  this.chgGrdCd = memGradeCd
                  this.chgGrdNm = memGradeNm
                  this.chgBenefit = this.getGradeBnftArray(memGradeIdx)
                } else {
                  console.log('>> 현 패밀리 그룹 등급이 멤버십 등급보다 같거나 낮은경우')
                  this.isBnftChange = false
                  this.nxtGrdIdx = fmlyGradeIdx
                  this.nxtGrdCd = fmlyGradeCd
                  this.nxtGrdNm = memGradeNm
                  this.nxtBenefit = this.getGradeBnftArray(fmlyGradeIdx)

                  //테스트
                  // this.isBnftChange = true
                  // this.nxtBenefit = this.getGradeBnftArray(2)
                  // this.chgBenefit = this.getGradeBnftArray(1)
                }
              } else {
                console.log('>> 패밀리 다음달 등급')
                this.isBnftChange = false
                this.nxtGrdCd = resData.expctLastCustGrdCd
                this.nxtGrdIdx = this.getGradeArr(resData.expctLastCustGrdCd).grdIdx
                this.nxtGrdNm = this.getGradeArr(resData.expctLastCustGrdCd).grdNm
                this.nxtBenefit = this.getGradeBnftArray(this.nxtGrdIdx)
              }
              console.log('')
              console.log('@@@@@@@@@@@@ 노출 결과 설정 : 혜택변경여부 : ' + this.isBnftChange)
              console.log(
                '>> nxtGrdCd : ' + this.nxtGrdCd + ', nxtGrdIdx : ' + this.nxtGrdIdx + ', nxtGrdNm : ' + this.nxtGrdNm
              )
              if (this.isBnftChange) {
                console.log(
                  '>> chgGrdCd : ' + this.chgGrdCd + ', chgGrdIdx : ' + this.chgGrdIdx + ', chgGrdNm : ' + this.chgGrdNm
                )
              }
              console.log({nxtBenefit: this.nxtBenefit, chgBenefit: this.chgBenefit})
              console.log('@@@@@@@@@@@@ 노출 결과 설정')
            } else {
              console.log('## 1단계 : 탈퇴 전 멤버십 다음달 등급 조회 ')
              this.isGrdDiff = true
              this.memNextGradeData = resData
              console.log(this.memNextGradeData)
              console.log('## 2단계 : 탈퇴 전 패밀리 다음달 등급 조회 등급비교대상 : ' + this.isGrdDiff)
              this.retrieveChNxtmmGrdRlrslt(true)
            }
          } else {
            console.log(
              '### 패밀리 그룹 다음달 등급 조회 중 오류 resCd : ' + resData.resCd + ', msg : ' + resData.resMsg
            )
            this.onAlert(resData.resCd, resData.resMsg)
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })

      // console.log('#### methods 멤버십/패밀리 서비스[G001 : retrieveGrdRlrslt] end ####')
    },
    // F006		saveChFamyGrpWthdrw	패밀리 그룹 탈퇴 API
    async saveChFamyGrpWthdrw() {
      console.log('#### methods 패밀리 서비스 탈퇴[F006 : saveChFamyGrpWthdrw] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'saveChFamyGrpWthdrw',
        famyGrpId: this.famyGrpId, //		패밀리그룹ID
        wthdrwChanlCd: this.$store.state.apiStore.bodyParam.chanlCd //		탈퇴채널코드
        // siteId: this.$store.state.apiStore.bodyParam.siteId,
        // intgMemNo: this.$store.state.apiStore.bodyParam.intgMemNo,
        // trnscNo: this.$store.state.apiStore.bodyParam.trnscNo
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/saveChFamyGrpWthdrw', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          console.log(resData)
          if (!this.$isEmpty(resData)) {
            console.log('>> 탈퇴 완료 처리 레이어 활성화')
            this.onLayer('complete', true)
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('#### methods 패밀리 서비스 탈퇴[F006 : saveChFamyGrpWthdrw] end ####')
      return
    },
    //정리 부분
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    }
  }
}
</script>
