<template>
  <div class="membership-box integrate">
    <!-- 
      // URL Path 기준 : 통합멤버십/패밀리그룹 안내
      //                 패밀리 그룹 만들기(생성) 안내
      // 두가지 용도 페이지 이용
    -->
    <!-- 패밀리 초대 -->
    <div class="family-member-wrap">
      <!-- 통합 멤버십이란? -->
      <div v-if="isWhatGsMemshp" class="integration-explanation">
        <div class="tit-area">
          <h1 class="tit">GS리테일 통합 멤버십</h1>
          <p class="desc lg point">
            <b>GS리테일 3개 브랜드</b> 중<br /><b>가장 높은 등급</b>으로 <b>모든 브랜드 등급 적용</b>
          </p>
          <p class="desc">온 · 오프라인에서 <b>다양한 혜택</b>을 받으세요!</p>
        </div>
        <div class="img-gsbrand">
          <img src="@/assets/images/member/intg-brand02.png" alt="브랜드 이미지" />
          <div class="tit-area">
            <h1 class="tit md">GS리테일 모든 브랜드</h1>
            <p class="emphasis">VVIP</p>
          </div>
        </div>
        <div class="tit-area">
          <p class="highlight tip">등급을 올릴 수 있는 또 한가지 방법!</p>
          <h1 class="tit">GS리테일 브랜드 합산</h1>
          <p class="desc">
            <b>GS리테일 3개 브랜드</b> 중 <b>2개 이상 브랜드</b>에서<br />
            <b>구매한 금액을 기준</b>으로 등급 부여
          </p>
        </div>
        <div class="detail-infobox">
          <header class="sect-head">
            <div class="head">
              <span><img src="@/assets/images/gs25_hor.svg" alt="GS25" /></span>
              <span><img src="@/assets/images/gsthefresh_hor.svg" alt="GS더 프레시" /></span>
              <span>
                <img src="@/assets/images/gsshop_hor02.svg" alt="GS SHOP" />
              </span>
            </div>
          </header>
          <div class="grade">
            <p><dfn class="badge">vvip</dfn>최근 3개월 총 구매금액 90만원 이상</p>
            <p><dfn class="badge">vip</dfn>최근 3개월 총 구매금액 60만원 이상</p>
          </div>
        </div>
        <div class="b-btn-wrap">
          <div>
            <button @click="goPage('standard')" class="check lg"><span>전체 혜택 한눈에 보기</span></button>
          </div>
        </div>
      </div>
      <!-- 통합 멤버십이란? -->

      <!-- 소개글 -->
      <section v-if="isWhatGsMemshp" class="family-intro">
        <!-- 그룹 생성 설명 -->
        <div class="intro group">
          <div class="tit-area">
            <p class="desc">패밀리 생성 3초면 끝!</p>
            <h1 class="tit"><span class="point">패밀리</span> 만들고 <br />멤버 합산실적으로 빠른 등급 UP!</h1>
            <p class="desc point">가족, 친구 누구나 최대 4명까지</p>
          </div>

          <img src="@/assets/images/member/family-intro.png" alt="패밀리 이미지" />

          <p class="desc">
            패밀리를 만들어 멤버들의 구매 실적을<br />
            하나로 모아 <b>멤버십 등급 UP</b>과 <b>등급 혜택</b>을 함께 <br />
            누릴 수 있는 멤버십 서비스입니다.
          </p>
        </div>
        <!-- //그룹 생성 설명 -->

        <!-- 포인트 지급 -->
        <div class="intro point">
          <img src="@/assets/images/member/family-gspoint.png" alt="GS&POINT 1000포인트 지급" />

          <div class="tit-area">
            <h1 class="tit">패밀리 서비스 최초 가입 시,<br />GS&POINT 1,000P 지급!</h1>
            <sub>패밀리 멤버 2인 이상시 지급</sub>
          </div>
        </div>
        <!-- //포인트 지급 -->
      </section>
      <!-- //소개글 -->

      <!--  UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->
      <section v-if="!isWhatGsMemshp" class="family-intro type02">
        <!-- 그룹 생성 설명 -->
        <div class="intro group">
          <div class="tit-area">
            <h1 class="tit-lg">GS리테일 통합 멤버십 <br /><span class="point">패밀리 서비스</span>란?</h1>
            <p class="desc">
              패밀리를 만들고, 멤버를 초대해<br />
              구매 실적을 함께 쌓고 멤버십 혜택도<br />
              함께 누릴 수 있는 서비스예요!
            </p>
          </div>
        </div>
        <!-- //그룹 생성 설명 -->
        <div class="family-box">
          <div class="tit">
            <h2>패밀리 멤버의 구매 실적이 모여 <br /><strong class="big point">더 빠르게 등급 UP!</strong></h2>
          </div>
          <div class="integration-explanation">
            <p class="emphasis">VVIP</p>
          </div>
        </div>
        <div class="family-box">
          <div class="tit">
            <h2>함께 달성한 멤버십 등급의 혜택도 <br /><strong class="big point">멤버 모두 똑같이 받아요!</strong></h2>
          </div>
          <figure class="img-family">
            <img src="@/assets/images/member/family-intro.png" alt="패밀리 이미지" />
          </figure>
        </div>
        <div class="family-box">
          <div class="tit">
            <h2>첫 패밀리 결성 시, <br /><strong class="big point">GS&POINT 1,000P 지급!</strong></h2>
            <sub>단, 패밀리 멤버 2명 이상 시 지급</sub>
          </div>
          <figure class="img-point">
            <img src="@/assets/images/member/family-gspoint.png" alt="GS&POINT 1000포인트 지급" />
          </figure>
        </div>
        <div class="family-box">
          <div class="tit">
            <h2>GS SHOP, 우리동네GS <br /><strong class="big point">어느 앱이든 패밀리 이용 가능!</strong></h2>
          </div>
          <!--  UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->
          <figure class="img-app">
            <img src="@/assets/images/member/app-logo-gsshop.png" alt="gsshop" />
            <img src="@/assets/images/member/app-logo-woodongs.png" alt="gsshop" />
          </figure>
        </div>
        <div class="family-box">
          <div class="tit">
            <h2>
              멤버는 최대 4명까지 <br />
              <strong class="big">
                <em class="point">가족, 친구 누구나 가능!</em> <br />지금 패밀리를 시작하세요
              </strong>
            </h2>
          </div>
        </div>
      </section>
      <!-- // UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->

      <!-- 자주 묻는 질문 -->
      <section v-if="isWhatGsMemshp" class="family-qna">
        <!-- title -->
        <div class="tit-area">
          <h1 class="tit"><span class="point">패밀리 서비스</span> 자주 묻는 질문!</h1>
        </div>
        <!-- //title -->

        <!-- qna -->
        <div class="qna-area">
          <dl class="qna-box">
            <dt class="question">멤버십 혜택을 나눠서 사용하는 건가요?</dt>
            <dd class="answer">
              <p>만약 VVIP 등급이라면 패밀리 멤버 모두 VVIP 등급 쿠폰을 받아요!</p>
            </dd>
          </dl>
          <dl class="qna-box">
            <dt class="question">패밀리는 가족끼리만 멤버가 될 수 있나요?</dt>
            <dd class="answer">
              <p>가족이 아니어도 GS리테일 멤버십 가입된 누구든 함께 최대 4인까지 멤버가 될 수 있어요!</p>
            </dd>
          </dl>
          <dl class="qna-box">
            <dt class="question">실적을 함께 쌓으면 멤버가 내 구매내역도 알 수 있나요?</dt>
            <dd class="answer">
              <p>멤버들의 구매내역은 알 수 없어요. 비밀 보장 가능합니다!</p>
            </dd>
          </dl>
        </div>
        <!-- //qna -->
      </section>
      <!-- //자주 묻는 질문 -->
    </div>
    <!-- //패밀리 초대 -->

    <!-- 서비스 유의사항 -->
    <div class="family-notice drop-box" :class="isFmlyNotice ? 'drop-open' : ''">
      <div class="drop-tit">
        <button type="button" class="noti-tit" @click="onLayer('notice')">서비스 유의사항</button>
      </div>
      <div class="drop-cont">
        <ul class="dot-list">
          <li>패밀리 서비스는 GS리테일 통합회원 및 패밀리 서비스 이용 약관에 동의하셔야 이용 가능합니다.</li>
          <li>패밀리는 1개만 생성 및 이용 가능하며, 멤버는 총 4명까지 가능합니다.</li>
          <li>
            패밀리 생성 후 멤버 1명 추가 시 또는 초대 받은 패밀리에 가입 시 최초 1회 GS&POINT 1,000포인트가 지급되며,
            포인트를 받은 이후에는 패밀리 생성 및 초대해도 포인트는 지급되지 않습니다.
          </li>
          <li>GS&POINT 미 가입 GS SHOP 회원은 이벤트 적립금으로 지급됩니다.</li>
          <li>
            전월 25일 24시에 등록된 구성원을 기준으로 구성원별 구매실적을 합산하여 당월 등급이 산정됩니다 (매월 1일
            반영되는 등급은 채널에 따라 3개월/6개월 26일~전월 25일까지 실적을 기준으로 합니다.)
          </li>
          <li>초대장을 통해서만 패밀리 가입이 가능합니다.</li>
        </ul>
      </div>
    </div>
    <!-- //서비스 유의사항 -->
    <!-- 패밀리 서비스 시작하기 -->
    <!-- CASE 1 : 패밀리 그룹 미가입 상태(통합회원) : '패밀리 서비스 시작하기' 노추 -->
    <!-- CASE 2 : 패밀리 그룹 미가입 상태(미통합회원) : '패밀리 서비스 시작하기' 노출 -->
    <!-- CASE 3 : 패밀리 그룹 가입 상태(통합회원) : '패밀리 긃 바로가기' 노출 -->
    <div class="family-btn-wrap">
      <div>
        <button v-if="!isIntegrate" @click="onFmlyCreate" class="btn-family">
          <span>GS리테일 통합 멤버십</span><span>시작하기</span>
        </button>
        <button v-else-if="isCreateFmly" @click="onFmlyCreate" class="btn-family">
          <span class="ico-family_logo">패밀리</span><span>시작하기</span>
        </button>
        <button v-else @click="goPage('family')" class="btn-family">
          <span class="ico-family_logo">패밀리</span><span>바로가기</span>
        </button>
      </div>
    </div>
    <!-- 패밀리서비스 약관 보기 Layer -->
    <ModalToastTerms v-if="isFmlyTerms" @onLayer="onLayer" @tagging="tagging" />
    <!-- 공통 Alert 레이어 -->
    <ModalAlert v-if="isAlert" @onLayer="onLayer" :alert="alert" :type="alert.type" />
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
    <!-- //패밀리 서비스 시작하기 -->
  </div>
</template>

<script>
import ModalToastTerms from '@/views/comm/integrate/ModalToastTerms.vue'
import ModalAlert from '@/views/comm/integrate/ModalAlert.vue'
import TagManager from '@/views/comm/TagManager.vue'

// 추가 작업 필요
// 1. 미통합멤버 진입시 오류 고지 후 채널 통합회원가입화면 이동 처리 필요
// 2. API 오류 시 고지 후 마이쇼핑 이동 처리

export default {
  name: 'IntgFamilyInfo',
  inject: ['sendTitle'],
  data() {
    return {
      title: '패밀리 서비스',
      // 기본 정보
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      chanlDomain: this.$store.state.apiStore.bodyParam.chanlDomain,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      isWhatGsMemshp: false,
      // 레이어
      isFmlyNotice: false, //패밀리서비스 유의사항 보기
      isFmlyTerms: false, //패밀리서비스 이용 약관
      // 데이터
      isIntegrate: true, //통합고객 여부
      isCreateFmly: true, //패밀리생성 가능여부
      trmsAgreeYn: 'N', //약관동의고객여부
      trmsAgreeDtm: '', //약관동의일자

      // <!-- 알럿(예외처리) -->
      isAlert: false, //알럿(예외처리) 노출 여부
      // 알럿(예외처리) 정보
      alert: {
        type: 'error', // 유형
        code: '', // (노출)코드
        message: '' // (노출)메시지
      }, //알럿(예외처리) 코드/메세지 정보
      // <!-- //알럿(예외처리) -->

      // 테스트 데이터
      isTest: false
    }
  },
  components: {
    ModalToastTerms,
    ModalAlert,
    // 앰플리튜드 기능 추가
    TagManager
  },
  created() {
    // console.log(this.$route),
    console.log('')
    console.log('######## 패밀리 그룹 소개/만들기(인트로) UI, GS레테일 통합 멤버십이란? UI ########')
    console.log('')

    if ('IntgFamilyInfo' === this.$route.name) {
      console.log('##### 패밀리 그룹 소개, 만들기(인트로) UI 설정')
    } else {
      this.isWhatGsMemshp = true
      this.title = 'GS레테일 통합 멤버십'
      console.log('##### GS레테일 통합 멤버십이란? UI 설정')
    }
  },
  mounted() {
    console.log('####### mounted str #######')
    document.title = this.title
    this.sendTitle(this.title)
    window.scrollTo(0, 0)

    this.isTest = false

    if (this.isTest) {
      console.log('## 1단계 : 테스트 데이터 설정')
      this.isIntegrate = true //통합고객 여부
      this.isWhatGsMemshp = true //GS리테일패밀리 소개 보기
      this.isFmlyNotice = true //패밀리서비스 유의사항 보기
      this.isFmlyTerms = false //패밀리서비스 이용 약관
      this.isCreateFmly = true //패밀리생성 가능여부
      this.trmsAgreeYn = 'N' //약관동의고객여부
      this.trmsAgreeDtm = '' //약관동의일자
      console.log('## 2단계 : 알럿(예외처리) 테스트 설정')
    } else {
      console.log('## 1단계 : 패밀리 생성 가능 여부 체크')
      this.retrieveChFamyGrpOpenPsbl()
    }

    // 앰플리튜드 추가
    this.tagging('VIEW-INTG-FMLY-INF')
    console.log('####### mounted end #######')
  },
  methods: {
    // 레이어 통합 관리
    onLayer(type, boolean) {
      console.log('>>> type : ' + type + ', boolean : ' + boolean)
      switch (type) {
        case 'terms': // 다음달 등급 적용 레이어 (비)노출 처리
          this.isFmlyTerms = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        case 'notice': // 서비스 유의사항 레이어 (비)노출 처리
          if (this.isFmlyNotice) {
            this.isFmlyNotice = false
          } else {
            this.isFmlyNotice = true
          }
          break
        case 'alert': // 알럿(예외처리) 레이어 (비)노출 처리
          this.isAlert = boolean

          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
            if ('4144' == this.alert.code) {
              console.log('미통합회원 알럿(예외처리) 닫은 후 채널 이동 처리')
              this.windowMove()
            } else {
              console.log('그외 채널 이동 처리')
              this.windowClose()
            }
          }
          break
        default:
          break
      }
      return
    },
    // URL이동 통합 관리
    goPage(type) {
      console.log('>>> 링크 화면이동 처리 type : ' + type)
      switch (type) {
        case 'standard': //GS통합멤버십 등급 소개 이동
          console.log('>>> GS통합멤버십 등급 소개 이동....')
          this.$router.push('/montblanc/intg/gradeStandard')
          break
        case 'family': //패밀리 그룹 가입 고객 패밀리 등급 이동
          console.log('>>> 패밀리 그룹 가입 고객 패밀리 등급 이동....')
          // 앰플리튜드 추가
          this.tagging('CLICK-INTG-FMLY-INF-MOVE')
          this.$router.push('/montblanc/intg/memshpGrade')
          break
        default:
          break
      }
      return
    },
    // <!-- 알럿(예외처리) 함수 -->
    onAlert(code, message) {
      console.log('>>> onAlert(알럿/예외처리) 기능 code : ' + code + ', message : ' + message)
      this.alert.code = code
      if ('4144' === code) {
        // 4144 resMsg : 통합고객번호 없는 경우 callback 필요함.
        this.alert.message = '패밀리 서비스 이용을 위해 GS리테일 통합회원 가입이 필요합니다'
      } else if ('4104' === code || '4100' === code || '4102' === code) {
        // 4100 resMsg : 기가입 패밀리 그룹이 존재하여 패밀리 그룹 개설은(는) 불가능합니다.
        // 4102 resMsg :
        // 4104 resMsg :
        this.alert.message = message
      } else if (code.startsWith('50')) {
        // 50% resMsg : 미인증 또는 인증이 만료되었습니다. 새로고침해 주세요.
        this.alert.message = '미인증 또는 인증이 만료되었습니다. 새로고침해 주세요.'
      } else if (this.$isEmpty(code) || '9999' === code) {
        //errCode가 없는 경우 입력된 message를 설정
        this.alert.message = message
      } else {
        console.log('>>> 알럿(예외처리) 코드 맵핑 없음')
      }

      this.onLayer('alert', true)
    },
    // <!-- //알럿(예외처리) 함수 -->
    // 레이어 띄울때 body fixed
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
      // this.nowOffset = window.scrollY
      // document.body.style.top = '-' + this.nowOffset + 'px'
    },
    // 레이어 띄울때 body fixed 제거
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
      // window.scrollTo(0, this.nowOffset)
    },
    // app/web 체크하여 창닫기 함수.
    windowClose() {
      console.log('#### windowClose back ####')
      sessionStorage.removeItem('inCheck1')
      //GSSHOP 앱(웹뷰) 여부 체크
      // alert('패밀리서비스 이동 임시 알럿:' + navigator.userAgent + ', isApp[' + this.isApp + ']')
      if (this.isApp) {
        if ('2010' === this.chanlCd || '3010' === this.chanlCd) {
          // alert('패밀리서비스 임시 창닫기 : 앱 닫기 toapp://close 호출')
          location.href = 'toapp://close'
        } else {
          // alert('패밀리서비스 임시 창닫기 : 앱 뒤로가기 toapp://back 호출')
          location.href = 'toapp://back'
        }
      } else {
        // alert('패밀리서비스 임시 창닫기 : 웹 뒤로가기 go(-1) 호출')
        this.$router.go(-1)
      }
      console.log('#### 패밀리서비스 windowClose app/web 창닫기 끝')
    },
    //통합회원가입페이지 이동처리
    windowMove() {
      console.log('#### windowMove back ####')
      sessionStorage.removeItem('inCheck1')
      // alert('패밀리서비스 이동 임시 알럿:' + navigator.userAgent + ', isApp[' + this.isApp + ']')
      if (this.isApp) {
        //통합회원가입 이동 처리
        if ('2010' === this.chanlCd) {
          // alert('패밀리서비스 이동 임시 창닫기 : 우동G app 통합회원 바로가기 toapp://통합회원가입 호출')
          location.href = 'toapp://integrate_customer_change'
        } else if ('3010' === this.chanlCd) {
          // alert('패밀리서비스 이동 임시 창닫기 : GS SHOP app 통합회원 바로가기 toapp://통합회원가입 호출')
          location.href =
            'toapp://newpage?closeYn=Y&url=' + this.chanlDomain + '/member/intgCustChgTermsForm.gs?mode=montblanc'
        } else {
          // alert('패밀리서비스 이동 임시 창닫기 : GS Fresh app 뒤로가기 toapp://back 호출')
          location.href = 'toapp://back'
        }
      } else {
        //통합회원가입 이동 처리
        if ('3010' === this.chanlCd) {
          // alert('패밀리서비스 이동 임시 창닫기 : GS SHOP web 통합회원 바로가기 이동')
          location.href = this.chanlDomain + '/member/intgCustChgTermsForm.gs?mode=montblanc'
        } else {
          // alert('패밀리서비스 이동 임시 창닫기 : GS Fresh web 뒤로가기 go(-1) 호출')
          this.$router.go(-1)
        }
      }
      console.log('#### 패밀리서비스 이동 windowMove app/web 이동 끝')
    },
    // AS-IS 컴포넌트 사용 함수
    // 정리 버전

    onFmlyCreate() {
      console.log('#### 패밀리 생성 이동 시작')
      // CASE 1 : 미통합회원 경우 채널 통합회원 전환 이동 처리
      if (!this.isIntegrate) {
        console.log('>> 미통합 회원으로 채널 통합회원전환 이동 처리')
        this.onAlert('4144', '패밀리 서비스 이용을 위해 GS리테일 통합회원 가입이 필요합니다')
        return
      } else if (!this.isCreateFmly) {
        console.log('>> 패밀리 가입 고객인 경우 패밀리 그룹으로 이동 처리')
      }

      // CASE 2 :
      // 약관 동의 여부 체크 및 패밀리 생성 이동 처리
      if ('N' === this.trmsAgreeYn) {
        this.onLayer('terms', true)
      } else {
        console.log('@@@ 패밀리 생성 화면 이동.')
        // 앰플리튜드 추가
        this.tagging('CLICK-INTG-FMLY-INF-CRE')
        this.$router.push('/montblanc/intg/familyName')
      }
    },
    // API 호출
    //  통합고객 패밀리 서비스 약관동의 여부 조회  API
    //  약관 동의 대상인 경우 약관 layer 노출 후 생성화면으로 이동
    //  약관 기동의 대상인 경우 생성화면으로 이동.
    async retrieveChFamyGrpOpenPsbl() {
      console.log('####### methods 1단계 : 패밀리 생성 가능 여부[F001 : retrieveChFamyGrpOpenPsbl] str #######')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpOpenPsbl'
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpOpenPsbl', bodyParam, {headers: headerParam})
        .then((res) => {
          var resData = res.data

          //응답 결과코드
          //응답 코드가 '0000' 이면 패밀리 그룹 생성 가능
          if (null != res && null != resData && '0000' === resData.resCd) {
            this.isCreateFmly = true

            // 패밀리 생성 가능 고객 만 동의 여부를 체크해서 노출 유무를 판단한다.
            console.log('## 결과 : 패밀리 그룹 생성 가능 여부 isCreateFmly : ' + this.isCreateFmly)
            this.retrieveChFamyGrpTrmsAgree()
          } else {
            this.isCreateFmly = false
            console.log('## 패밀리 생성 불가능 고객으로 이동 버튼 노출.')
            console.log('#### API 호출 resCd : ' + resData.resCd + ' resMsg : ' + resData.resMsg)
            //미통합회원 경우 오류코드[4144]인 경우 '패밀리 서비스 시작하기' 버튼 클릭 시 채널 통합전환 이동 처리 필요함.
            if ('4144' === resData.resCd) {
              this.isCreateFmly = true //미통합회원 버튼 노출처리
              this.isIntegrate = false //미통합회원 설정
            }
            // 그외 패밀리 생성이 되어 있는 경우로 '패밀리 그룹 가기' 버튼 노출 처리
          }
          console.log('>> isCreateFmly : ' + this.isCreateFmly + ', isIntegrate : ' + this.isIntegrate)
        })
        .catch((e) => {
          this.isCreateFmly = true
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('####### methods 1단계 : 패밀리 생성 가능 여부[F001 : retrieveChFamyGrpOpenPsbl] end #######')
    },
    //  API 조회 기능 시작
    //  통합고객 패밀리 서비스 약관동의 여부 조회  API
    //  약관 동의 대상인 경우 약관 layer 노출 후 생성화면으로 이동
    //  약관 기동의 대상인 경우 생성화면으로 이동.
    async retrieveChFamyGrpTrmsAgree() {
      console.log('####### methods 2단계 : 약관 동의 여부[F007 : retrieveChFamyGrpTrmsAgree] str #######')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpTrmsAgree'
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpTrmsAgree', bodyParam, {headers: headerParam})
        .then((res) => {
          var resData = res.data

          //응답 결과코드
          if (null != res && null != resData && '0000' === resData.resCd) {
            var trmsAgreeYn = resData.trmsAgreeYn

            console.log('## 약관동의 고객 여부 trmsAgreeYn : ' + trmsAgreeYn)
            //통신 후 동의 여부가 Y인 경우
            if (null != trmsAgreeYn && 'Y' === trmsAgreeYn) {
              this.trmsAgreeYn = 'Y'
              this.trmsAgreeDtm = resData.trmsAgreeDtm
              console.log('## 약관동의 고객으로 약관 미노출 처리')
            } else {
              this.trmsAgreeYn = 'N'
              console.log('## 약관미동이 고객으로 약관 노출 처리')
            }
          } else {
            console.log('#### API 호출 resCd : ' + resData.resCd + ' resMsg : ' + resData.resMsg)
            // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
            this.trmsAgreeYn = 'N'
            console.log('## api 오류시 약관 미동이 고객으로 약관 노출 처리')
            // this.setConfirmMsg('[' + resData.resCd + ']' + resData.resMsg)
            // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
          }
          this.isFmlyNotice = true // 서비스 유의사항 항상 펼침
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('####### methods 2단계 : 약관 동의 여부[F007 : retrieveChFamyGrpTrmsAgree] end #######')
    },
    // API 호출
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    }
  }
}
</script>
